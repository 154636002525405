<template>
    <v-container
        id="dashboard"
        fluid
        tag="section"
    >
        <div class="show">
            <div class="img-wrapper">
                <img src="https://assets.koocoding.com/assets/files/b2845ebb2b645a84b91acb397fd30a17.jpg" alt="">
                <div class="show-text">
                    <div class="text">校区管理</div>
                    <div class="text">即将上线，敬请关注</div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "Coming Soon",
    data() {
        return {
          
        };
    },
    mounted() {
    },
    activated() {
    },
    methods: {
    },
    components: {
    }
};
</script>

<style lang="scss" scoped>
.show {
    // height: 500px;
    width: 100%;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .img-wrapper {
        position: relative;
        width: 80%;
        img {
            width: 100%;
            // border: 10px solid #efefef;
            box-shadow: 0px 3px 15px #ddd;
        }
        .show-text {
            position: absolute;
            top: 80px;
            left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            
            .text {
                font-size: 32px;
                font-weight: bold;
                color: #666;
                letter-spacing: 2px;
            }
        }
    }
}
</style>